::-webkit-scrollbar{
width: 15px;
background: black;
}
::-webkit-scrollbar-thumb{
background:#4b464b;
border-radius: 6px;

}

::-webkit-scrollbar-thumb:hover{
background:linear-gradient(to bottom,#D729DE, #7521A8);
cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}


input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #0e0e0f inset !important;
  -webkit-text-fill-color: white !important;
}




@tailwind components;
@tailwind utilities;
@font-face{
    src: url(assets/fonts/Gilroy-ExtraBold.ttf);
    font-family: "gilroybold";
}
@font-face{
    src:url(assets/fonts/Gilroy-UltraLight.ttf);
    font-family: "gilroythin";
}
@font-face {
    font-family: "gilroylight";
    src: url(assets/fonts/Gilroy-Light.ttf);
}
@font-face {
    font-family: "gilroymedium";
    src: url(assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
    font-family: "gilroysemibold";
    src: url(assets/fonts/Gilroy-SemiBold.ttf);
}

.typing-text img{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 700px;


}

@media only screen and (max-width: 480px) {
    .typing-text img{
        max-width: 300px;
        max-height: 60px;
    
    }
}

@media only screen and (min-width: 786px) {
    .typing-text img{
        max-width: 600px;
        max-height: 80px;
    
    }
}



.mainbg{
    background-image: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653296935/codler/bg_1_3_h5hxvp.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.portfoliobg{
    background-image: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1652799840/codler/bg_1_wevkwv.png');
    background-repeat: no-repeat;
    background-size: cover;
    height:94vh;
    
}
@media (max-width:480px) {
    .mainbg{
        height: 80vh;
    }
}
/* contact */
.contact-btn{
    box-shadow: #202020;
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.2) 1.7%, rgba(0, 0, 0, 0) 97.81%),
linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(65, 62, 62, 0.11));

}
.button_txt{
    background-image: linear-gradient(to right,rgb(27, 27, 196),rgb(213, 55, 81));
}
.button_txt:hover{
    background-image:linear-gradient(to right,rgb(34, 31, 31),rgb(87, 79, 79));
}
.contact-shade{
    background: #5E06CF;

}
/* contact end */

/* service */

.noise{
    width:100%;
    height:100%;
    content:"";
    z-index:500;
    pointer-events: none;
    background: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653296936/codler/Rectangle_ud7ptk.webp');
}
.servebg{
    background-image: url('images/services/bg-wave.jpg');
    background-position: center;
    z-index: 0;
}
@media (max-width:480px){
    .servebg{
        height: 80vh;
    }
}

.s-bg1{
    background: linear-gradient(96.92deg, #7521A8 41.05%, #EC2BEA 152.34%);

}
.sbg1-btn{
background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)); 
    
}

.s-bg2{
    background: #5E06CF;

}

.sbg2-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));
    
}

.s-bg3{
    background: linear-gradient(180deg,#CB2552 0%, #EF5E6B 100%);

}

.sbg3-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));
    
}

.s-bg4{
    background: linear-gradient(278.22deg, #F4A225 0%, #DC4601 102.4%);

}

.sbg4-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));

}

.s-bg5{
    background: linear-gradient(180deg, #7D2961 0%,  #380B2D 100%);

}

.sbg5-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));

}
.s-bg6{
    background: linear-gradient(180deg, #131212 0%, #433D43 100%);
}
.sbg6-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.13) 1.7%, rgba(0, 0, 0, 0) 97.81%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));
    
}



.serv-brand{
    background: linear-gradient(180deg, #b026c8ea 0%, #7421a8ef 100%);


}
/* service end */


/* s-inner */
.s-inner-bg{
    background-image: url('images/serviceinner/bg-wave.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    z-index: 0;

    /* filter: sepia(60%) hue-rotate(190deg) saturate(500%); */
}
@media (max-width:480px){
    .s-inner-bg{
        height: 80vh;
    }
}

.bg-colors{
    background: #0F0F0F;

}

/* s-inner end */

/*  */
.blogbg{
    background-image: url('images/bloglist/bgblog.jpg');
    background-position: center;
    background-size: cover;
    height: 70vh;
    
}
@media (max-width:480px){
    .blogbg{
        height: 60vh;
    }
}
/*  */

/* footer */
.man-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.2) 1.7%, rgba(0, 0, 0, 0) 97.81%),
linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));

}
.footerbg{
    background-image: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659512237/codler/Group_116_1_tzh1nd.png');
    background-repeat: no-repeat;
    
    /* border-radius: 10px; */
    

}
@media (max-width:768px) {
    .footerbg{
        height: 20vh;
    }
}


/* footer */

/* portfoli */
.port-bg{
    background: #5E08CF;
    border-radius: 10px;
}
.back1-bg{
    background: #F1F1F1;
}
.back2-bg{
    background: #E2F2FB;
}
.back3-bg{
    background: #4F52B0;
    
}
/* @media (max-width:400px) {
    .back3-bg{
        margin-top: -4.5rem;
    }
} */
@media (min-width:900px) {
    .back3-bg{
        margin-top: -4.5rem;
    }
}
.back4-bg{
    background: linear-gradient(180deg, #F3E9ED 3.44%, #CCD1F8 45.54%, #D6E8FC 69.72%, #E5F2FE 100%);
}
@media (max-width:400px){
    .port-bg{
        height: 80vh;
        width: 100%;
    }
}

.port-sec2-bg{
    background: #0F0F0F;

}
.port-sec3-bg{
    background-image: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655062721/codler/Web_Site_Image_ERP_7_uktqtd.jpg');
    height: 70vh;
    background-position: center;
    background-size: cover;
}
@media (max-width:480px){
    .port-sec3-bg{
        height: 50vh;
    }
}
 .port-sec4-bg{
    background-image: url('images/portfolio-images/port-inner/Rectangle\ 15\ \(1\).png');
    height: 30vh;
 }

 .role-btn{
    background: linear-gradient(139.63deg, rgba(255, 255, 255, 0.2) 1.7%, rgba(0, 0, 0, 0) 97.81%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11));
    
 }
 
/* portfoli2 end */

.image4{
    height:360px;
    width:900px;
}
.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
}

    .marquee span {
        display: inline-block;
        padding-left: 100%;
        animation: marquee 20s linear infinite;
    }

.marquee2 span {
    animation-delay:10s;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}
.smimages {
    width: 21%;
    height: 50%;
}
.smimages:hover {
       width: 23%;
        height: 53%;
        border:3px  black;
        border-radius:10px;
}
.section2-column{
    height:400px;
}
.box:hover{
    width:330px;
    height:330px;
}
.float {
    position: relative;
    -webkit-animation: floatBubble 2s infinite normal ease-out;
    animation: floatBubble 2s infinite normal ease-out;
}
.slide{
    animation: slidein 2s;
}
@keyframes slidein {
    0%{
        transform: translateX(-500px);
    }
    100%{
        transform: translateX(0);
    }
    
}
.bubble{
    bottom:0;
    overflow: hidden;
    position:fixed;
    animation:flying 15s infinite ease-in;
}
.bubble2{
    bottom:0;
    overflow: hidden;
    position:fixed;
    animation:flying2 20s infinite ease-in;
}
.bubble3{
    bottom:0;
    overflow: hidden;
    position:fixed;
    animation:flying2 30s infinite ease-in;
}
@keyframes flying{
    0%{
    transform:tralateX(0);
    left:10%;
    }
    50%{
     transform:tralateX(100px);
     left:50%;
    }
    100%{
        bottom:750px;
        left:90%;
        transform:tralateX(0)

    }
}
@keyframes flying2{
    0%{
    transform:translateY(0);
    right:10%;
    }
    50%{
     transform:translateY(-50px);
     right:50%;
    }
    100%{
        bottom:750px;
        right:90%;
        transform:translateY(0)

    }
}
@-webkit-keyframes floatBubble {
    0% {
        top: 500px;
    }

    100% {
        top: 0px;
    }
}

@keyframes floatBubble {
    0% {
        top: 500px;
    }

    100% {
        top: 0px;
    }
}
.bgimage{
    background-image:url("./images/backgroundshade.jpg");
}
.sectionbg {
    
    background: rgba(128,128,128, 0.2);
}
.sectionbg2 {
    background: rgba(128, 128, 128, 0.219);
    /* margin-top:-450px; */
    margin-top: 150px;
}
.sectionbg3 {
    
    background: linear-gradient( #800080 5.97% ,#1F76E9 34.29%, #800080 69.75%, #F70AB8 95.31%,#5408C7  5.97%, #1F76E9  34.29% ,#8F47E8 69.75% ,#F70AB8 95.31%);
}
.colortext {
    background: linear-gradient(to bottom, #000099 0%, #ff00ff 100%);
    -webkit-text-fill-color: transparent;
    /* -webkit-background-clip: text; */
}
.colorbubble{
    z-index:2;
    margin-top:-395px;
    width:600px;
    margin-left:790px
}
.main-content{
    background-image:url("./images/about-us-images/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    
}
.sectionbg4 {
    background: linear-gradient(to right,#000099,#5408C7,#1F76E9, #ff008c 10%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width:600px) {

    .main-content {
        height: 100vh;
    }
}
.wrap .cards:hover{
    transform: translateY(110px);
    
}

.wrap{
    background-color: #0F0F0F;
    z-index: 50;
}
.wrap .cards{
    animation-delay: 0s;
}
.wrap .cards2{
    animation-delay: 10s;
}
.wrap .cards3{
   animation-delay: 20s;
}
@keyframes animate {
    0%{
        opacity :1;
        transform:translateY(0) scale(0.3);
        margin-left: 50%;
    }
    50%{
        opacity :1;
        pointer-events: auto;
        transform:translateY(20px) scale(0.9);
        margin-left: 0;
    }
    100%{
        opacity :0;
        transform:translateY(500px) scale(0.3);
        margin-left:80%;
    }
   
}
.text-color:hover{
    background: linear-gradient(90deg,#000099,#fc00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.textlgcolor{
     background: linear-gradient(to right,#000099,#5408C7,#1F76E9, #ff008c 50%);
    -webkit-text-fill-color: transparent;
}
.textlbg{
    background: linear-gradient(to right,#F70AB8,#5408C7,#1F76E9,#8F47E8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.animated-text{
    height:20px;
    overflow: hidden;
}
.animated-text2{
    overflow:hidden;
    animation:moveup 50s infinite ease-in;

}

@keyframes moveup{
    0%{
    margin-top: -350px;
    }
    50%{
        margin-top: 0px;
    }
    100%{
        margin-top: -200px;

    }
}

.line{
    background: linear-gradient(90deg,#fc00ff,#000099);
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.line:first-child{
    animation: anim 14s infinite ease-in;
}
@keyframes anim {
    
    0%{
       margin-top: 0;
    }
    16%{

        margin-top: -20px;
    }
    27%{
    
        margin-top: -40px;
    }
    38%{
    
        margin-top: -60px;
    }
    50%{
        margin-top: -80px;
    }
    62%{
        margin-top: -60px;

    }
    71%{
        margin-top:-40px ;

    }
    82%{
    
        margin-top: -20px;
    }
    100%{
        margin-top: 0;

    }
}
.colorbox{
    width:120%;
}   
   
li{
    color:blue;
}
.faicon{
    margin-left:950px;
}
p{
    z-index:2;
    
}

.fas{
    width:50px;
    height:50px;
}

.titlename {
    font-family: Caesar Dressing;
}




/* navbar */

.linkss{
    color: #fff;
}

.wrapper{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background: linear-gradient(-135deg, #c850c0, #4158d0); */
    background-image: url('https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653338007/codler/mainbg_qm0qcg.webp');
    clip-path: circle(0px at calc(100% - 140px) 45px);
    transition: all 0.3s ease-in-out;
    z-index: 200;

  }
  #active:checked ~ .wrapper{
    clip-path: circle(75%);
  }
  .menu-btn{
    position: absolute;
    z-index: 2;
    right: 105px;
    /* left: 20px; */
    top: 24px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 25px;
    color: #fff;
    cursor: pointer;
    overflow-y: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 210;

  }
  @media (max-width:480px) {
    .wrapper{
        clip-path: circle(0px at calc(100% - 60px) 45px);
        height: 100%;
    } 
    .menu-btn{
        right: 20px;
    }
  }
  @media (max-width:840px) {
    .wrapper{
        clip-path: circle(0px at calc(100% - 60px) 45px);
        
    } 

  }


  #active:checked ~ .menu-btn{
    background: #202020;
    color: #fff;
  }
  #active:checked ~ .menu-btn i:before{
    content: "\f00d";
  }
  .wrapper ul{
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
  }
  .wrapper ul li{
    margin: 15px 0;
  }
  .wrapper ul li a{
    color: none;
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    padding: 5px 30px;
    color: rgba(255, 255, 255, 0.342);
    position: relative;
    line-height: 50px;
    transition: all 0.3s ease;
  }
  /* .wrapper ul li a:after{
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 50px;
    left: 0;
    border-radius: 50px;
    transform: scaleY(0);
    z-index: -1;
    transition: transform 0.3s ease;
  } */
  /* .wrapper ul li a:hover:after{
    transform: scaleY(1);
    
  } */
  .wrapper ul li a:hover{
    color: #fff;
  }
  input[type="checkbox"]{
    display: none;
  }

  /* navbar end */


  /*image slider css */

/* Slideshow container */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }
  
  /* Hide the images by default */
  .mySlides {
    display: none;
  }
  
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .active, .dot:hover {
    background-color: #717171;
  }

  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }
  .marq {
    
    white-space: nowrap;
    animation: rightThenLeft 5s linear infinite;
}

@keyframes rightThenLeft {
    0%   {transform: translateX(-40%);}
    50%  {transform: translateX(0%);}
    100% {transform: translateX(40%);}
}
._wPDyp {
    flex-basis:30%;
}
.portfolio-image-box{
    
}
.portfolio-image-outerbox{
    overflow: hidden;
    -webkit-box-shadow: 10px 15px 50px 0 #d5d5d5;
    box-shadow: 10px 20px 20px 0 #d5d5d5;
    position: relative;
    width: 100%;
    padding-top: 100%;
}
.portfolio-image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
}
.link-box{
    font-size: 12px;
    padding-left: 22px;
    background: rgba(0,0,0,.5);
    padding: 6px 15px;
    right: initial;
    border-radius: 100px;
    padding-left: 34px;
    bottom: 10px;
    left: 10px;
    -webkit-box-shadow: 6px 3px 11px 0 rgb(134 134 134 / 25%);
    box-shadow: 6px 3px 11px 0 rgb(134 134 134 / 25%);
    position: absolute;
    color: #fff;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    max-width: calc(100% - 60px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.portfolio-image-main{
    opacity: 1;
    transform: matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0.1, 1);
}
.show-eye{
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
    background: rgba(237,102,80,.71);
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
}

._wPDyp{
    flex-basis: calc(100%/3 - 10px) !important;
    padding:10px;
}
._wPDyp img{
    flex-basis: calc(100%/3 - 10px);
    border-radius: 5px;
}
.glass{
    background-color:rgba(255, 255, 255, 0.2);
    backdrop-filter:blur(30px);
    -webkit-backdrop-filter: blur(10px);
    padding: 15px;
    background:linear-gradient(to right,rgb(249, 20, 58),rgb(7, 7, 243),transparent);
    background-clip: padding-box;
    box-shadow:0.5px 0.5px 0.5px 0.5px rgb(217, 204, 204);
    transform: skew(45deg) translateX(0)
    }
.glass:hover{
    background: linear-gradient(to bottom,gray,transparent);
}
.glass a{
    position: relative;
    z-index: 2;
}
  /*image overlay*/
  .image_overlay{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      background: rgba(0,0,0,0.6);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity:0;
      transform: opacity 0.25s;
  }

  .image_overlay:hover{
      opacity: 1;
  }

  .image_overlay > * {
      transform: translateY(20px);
      transition: transform 0.25s ;
  }

  .image_overlay:hover> * {
      transform:translateY(0);
  }

  .image_overlay_blur {
      backdrop-filter: blur(5px);
  }

  /*swiper slider*/
  .container2 {
    position: relative;
    width: 100%;
    height: 60vh;
    background-color: #000;
    display: block;
  }
  .swiper-slide img{
    display:block;
    width:300px;
    height:300px;
    object-fit: cover;

  }
  
  .container2 .swiper-container {
    width: 90%;
    position: absolute;
    left: 0%;
    bottom: -16vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-90%);
    z-index: 2;
    padding-bottom: 3vw;
  }
  .title_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    padding: 3vw 0 2vw 0;
  }
  @media (max-width: 768px) {
    .title_wrapper .title_ {
      font-size: 7vw;
    }
  }
  
  .title_wrapper .title_ span {
    display: block;
  }
  
  .swiper-pagination-fraction {
    bottom: 0;
  }
  
  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 5px;
  }

  /*animated text*/
  .title_wrapper .title_ {
    color: #fff;
    text-align: left;
    font-size: 2vw;
  }
  .title_wrapper .title_ {
  color: #fff;
  text-align: left;
  font-size: 2vw;
}
.wavy{
   position:relative;
   -webkit-box-reflex:below -12px linear-gradient(transparent,rgba(0,0,0,0.2));
}
.wavy span{
    position:relative;
    display:inline-block;
    color:#fff;
    font-size:2em;
    text-transform:uppercase;
    animation:animate 2s ease-in-out infinite;
    animation-delay:calc(0.1s *var(--i));
}
@keyframes animate{
    0%{
       transform:translateY(0px);
    }
    20%{
        transform:translateY(-10px);
     }
     40%,100%{
        transform:translateY(0px);
     }

}




/* slider 3 */

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slider{
  width: 100%;
  height: 400px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.slide, .slide img{
  width: 100%;
  height: 350px;
  background: rgb(150, 133, 133);
}
.slide{
  /* opacity: .5; */
  transform: scale(.7);
  transition: .5s;
  filter: blur(5px);
}

.slide-active{
  filter: blur(0);
  opacity: 1;
  transform: scale(1.1);
}
.arrow{
  font-size: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 5px 3px rgba(204, 204, 204, 0.089);
  display: flex;
  width: 50px;
  position: absolute;
  top: -20px;
  z-index: 300;
  cursor: pointer;
}
.arrow:hover{
    color: rgb(141, 13, 141);
}
.arrow-right{
  right: 250px;
}
.arrow-left{
  left: 250px;
}
/* .dot{
  margin-top: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px #ccc;
}
.dot-active{
  background: #121227;
  box-shadow: 0 0 15px 10px #ccc;
} */
@media screen and (max-width: 768px) {


}

/* slider 3  end */

/* slider 2 */

  

  .arrow2{
    font-size: 40px;
    padding: 5px 10px;
    color: white;
    display: flex;
    width: 50px;
    position: absolute;
    top: 40%;
    z-index: 300;
    cursor: pointer;
  }
  .arrow2:hover{
      color: rgb(141, 13, 141);
  }
  .arrow-right2{
    right: -70px;
  }
  .arrow-left2{
    left: -70px;
  }

  @media (600px <= width <= 900px){
    .arrow-right2{
        right: -50px;
      }
      .arrow-left2{
        left: -50px;
      }
  }

  @media (200px <= width <= 600px){
    .arrow2{
        top: 3%;
    }
    .arrow-right2{
        right: 0px;
      }
      .arrow-left2{
        left: 0px;
      }
  }


.change-content:after{
    content:'';
    animation:changetext 10s infinite linear;
    
}

@keyframes changetext {
    0%{
        content:'Your vision & Our Idea';
    }
    50%{
        content:'Your vision & my technology';
    }
    100%{
        content:'The Most Reliable Marketing Company';
    }
    
}
.slick-slide{
    width:200px;
    box-sizing: border-box;
}
/* .scroll-main::-webkit-scrollbar{
    background: black;
    scrollbar-width:none;
}
.scroll-main::-webkit-scrollbar-thumb{
    background-color:black;
    
} */

/* hide scrollbar but allow scrolling */
.scroll-main {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
    transition: all 0.5s ease 0s;
    cursor: pointer;
  }
  
  .scroll-main::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  
.cont-btn button{
    transition-property: all;
  transition-duration: .6s;
  transition-timing-function: ease;
}
/* slider 2 end */


/* clinet */
.client-bg{
    background: transparent linear-gradient(180deg, #3a14528a 0%, #5E06CF 50%,#3f145a73 100%) 0% 0% no-repeat padding-box;
    /* background: transparent linear-gradient(180deg, ##7521A8 0%, ##5E06CF 50%,#7521A8 100%) 0% 0% no-repeat padding-box; */
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.insta-bg{

    background: transparent linear-gradient(180deg, #3a14528a 0%, #5E06CF 50%,#3f145a73 100%) 0% 0% no-repeat padding-box;
    /* background: transparent linear-gradient(180deg, ##7521A8 0%, ##5E06CF 50%,#7521A8 100%) 0% 0% no-repeat padding-box; */
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.brand-text{
    background: #BF27D1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.every-btn{
    transition: all 0.3s ease 0s;
}
.every-btn:hover{
    background: transparent linear-gradient(89deg, #D729DE 0%, #7521A8 100%) 0% 0% no-repeat padding-box;
}

.every-btn-non{
    transition: all 0.3s ease 0s;
    background: transparent linear-gradient(89deg, #D729DE 0%, #7521A8 100%) 0% 0% no-repeat padding-box;
}
.fa-brands{
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.logo-colr{
    background: transparent linear-gradient(180deg, #EC2BEA 0%, #7521A8 46%, #5E06CF 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.logo-colr:hover{
    background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff 46%, #ffffff 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.astonish{
    background: transparent linear-gradient(90deg, #7521A8 0%, #EC2BEA 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.lets-btn{
    transition: all 0.3s ease 0s;
}
.lets-btn:hover{
    background: transparent linear-gradient(90deg, #BF27D1 0%, #7521A8 100%) 0% 0% no-repeat padding-box;
}


.carduser-bg{
    transition: all 0.5s ease 0s;
    cursor: pointer;
}
/* clinet */

/* our strength */
.testinomial-shape{
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
}



.count-num{
    /* -webkit-text-stroke: 2px rgba(255, 255, 255, 0.151); */
    background: transparent linear-gradient(117deg, #EC2BEA 0%, #7521A8 48%, #5E06CF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
}
.view-all-btn{
    width:100px;
    background: linear-gradient(90deg,  #EC2BEA 0%, #7521A8 48%, #5E06CF 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
}

.plus-count{


    -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.925);
    color: #000000;
}

/* our strength end */

/* Accordian */



.accordion-simple > .active {
    display: block;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
  }
  
  .accordion__faq .inactive{
    display: none;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
  }

.acco-m > h3{
    background: transparent;
    
}
.acco-m > p{
    background: transparent;
    
}


  .accordion__faq > div{
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .accordion__title{
    color: white;
    padding-bottom: 20px;
    font-weight: 500;
  }
  
  .accordion__faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .accordion__faq-heading .active{
    color: white;
    
  }
  
  .accordion__faq-heading h3{
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  .accordion__faq p {
    margin: 0px;
    font-size: 18px;
    padding-bottom: 10px;
    color: rgba(255, 255, 255, 0.507);
    line-height: 1.4;
    
  }

  @media only screen and (max-width: 480px) {
    .accordion__faq-heading h3{
        font-size: 16px;
        
      }
        .accordion__faq p {

    font-size: 14px;

    
  }
}


  /* Accordian */


  .footer-body{
      background-image: url('https://res.cloudinary.com/dpsamxitw/image/upload/v1661853524/codler/lineshade_dtcd9u.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      
  }
