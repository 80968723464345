
.btn-effect{
transition: all .4s ease-out;
}
.btn-effect:hover{
transition: all .4s ease-out;
}

.banner-image{
    background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192596/codler%20branding%20page/Group_24632_2x_g85rpa.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 98vh;
    width: 100%;
}
@media (max-width: 500px) {
  .banner-image{
    height: 40vh;
  }
}
@media (max-width: 850px) {
  .banner-image{
    height: 40vh;
  }
}

.we-are{
    background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193218/codler%20branding%20page/Group_24634_vivgng.svg');
    
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (min-width: 1200px) {
    .we-are{
      height: 100vh;
    }
  }


.brands-img{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.418);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.349);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.253);
}

.expert{
    background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193218/codler%20branding%20page/Group_24634_vivgng.svg');
    
    background-repeat: no-repeat;
    background-size: cover;

}
@media (min-width: 1200px) {
    .expert{
      height: 115vh;
    }
  }




  .slick-slide > div {
    margin: 0 20px;

    /* background-color: aqua; */
  }
  .slick-list {
    margin: 0 -10px;
  }

  .card-bg{
    background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1667284884/codler%20branding%20page/testi-shape_yyvduf.svg');
    height:60vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 850px) {
    .card-bg{
      height:25vh;
      
    }
    .slick-slide > div {
      margin: 0 5px;
    }
  }

  @media (max-width: 500px) {
    .card-bg{
      height:50vh;
      
    }
    .slick-slide > div {
      margin: 0;
    }
  }

  @media (max-width: 380px) {
    .card-bg{
      height:60vh;

      
    }
    .slick-slide > div {
      width: 100%;
    }
  }


  .arrow {
    color: #DA29E0;
    font-weight: 500;
  }


  .page-inner-slider .slick-dots button:before{
    color:rgb(216, 206, 206)  !important;
  
  }
  .page-inner-slider .slick-dots li.slick-active button:before {
    color:White !important;
  }


  /* tabs */

  .tabs{
    background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193218/codler%20branding%20page/Group_24634_vivgng.svg');
    
    background-repeat: no-repeat;
    background-size: cover;
   
}









/* =============================================WEBSITE PAGE===================================================== */
:root{
  color: #7521A8;
  color: #EC2BEA;
  
}

.left-ban-div{
  border-width: 0;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: .8em;

  border-image: linear-gradient(to bottom, #7521A8, #8426be, #ffff) 1;
}

.left-ban-div h2{
 z-index: 10;
 position: relative;
}

.web-banner hr{
  margin-top: -30px;
  height: 30px;
  width: 100%;
  background: #EC2BEA;
position: absolute;

  z-index: 1;
 
}





.web-count .count-head{

    background: -webkit-linear-gradient(#EC2BEA, #7521A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    

}

.web-project hr{
  margin-top: -30px;
  height: 30px;
  width: 100%;
  background: #EC2BEA;
position: absolute;

  z-index: 1;
 
}

.web-project h4{
  z-index: 10;
  position: relative;
 }
 
.brand-fit{
  box-shadow: 0px 0px 2px 0px rgba(180, 178, 178, 0.75) inset;
-webkit-box-shadow: 0px 0px 2px 0px rgba(180, 178, 178, 0.75) inset;
-moz-box-shadow: 0px 0px 2px 0px rgba(180, 178, 178, 0.75) inset;
} 
.brand-fit{
  background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1668080510/codler%20digital%20marketing/Project_BG_mz52rh.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.e-book{
  -webkit-box-shadow: 0px 5px 10px -5px rgba(180, 178, 178, 0.75);
  -moz-box-shadow: 0px 5px 10px -5px rgba(180, 178, 178, 0.75);
  box-shadow: 0px 5px 10px -5px rgba(180, 178, 178, 0.75);
}

.service-br{
  border-width: 0;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: .5em;

  border-image: linear-gradient(to bottom, #7521A8, #ffffff, #ffff) 1;
}

.service-grid{
  box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
-webkit-box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
-moz-box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
}


.serv-br{
  height: 40px;
  width: 100%;
  background: #7521A8;
}

.testimonailss{
  box-shadow: 0px -6px 10px 0px rgba(180, 178, 178, 0.75);
  -webkit-box-shadow: 0px -6px 10px 0px rgba(180, 178, 178, 0.75);
  -moz-box-shadow: 0px -6px 10px 0px rgba(180, 178, 178, 0.75);
}

.why-you-bg{
  background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1668080509/codler%20digital%20marketing/Frame_452_xcjh9q.png');
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}

@media (max-width: 600px) {
  .why-you-bg{
    background-image: none;
  }
}



.clients-brands{
  box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
-webkit-box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
-moz-box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
}

.language{
  box-shadow: 0px 0px 10px 0px rgba(180, 178, 178, 0.75) inset;
-webkit-box-shadow: 0px 0px 10px 0px rgba(180, 178, 178, 0.75) inset;
-moz-box-shadow: 0px 0px 10px 0px rgba(180, 178, 178, 0.75) inset;

}
.language{
  background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1668080509/codler%20digital%20marketing/circles_ltjuil.svg');
  background-size: cover;
  background-repeat: no-repeat;

}

.lauch{
  border-width: 0;
  border-style: solid;
  border-top-width: .5em;
  border-right-width: 0;
  border-bottom-width: .5em;
  border-left-width: 0;

  border-image: linear-gradient(to right, #7521A8, #d3b0e9, #ffff) 1;
}

.web-form{
  box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(180, 178, 178, 0.75);
}

.web-form ::placeholder {
  color: rgb(78, 76, 76);
  opacity: 1; /* Firefox */

}
.web-form{
  position: relative;
  z-index: 20;
}

.webform{
  height: 100px;
  width: 100%;
  background: #7521A8;
  position:absolute; 
    top:50%; 
    left:50%; 
    transform:translate(-50%, -50%);
  z-index: 0;

}
.hubform{
  height: 100px;
  width: 100%;
  background: #7521A8;
  position:absolute; 
    top:50%; 
    left:50%; 
    transform:translate(-50%, -50%);
  z-index: 0;

}

.web-footer{
  box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(180, 178, 178, 0.75);
}

.web-slider img{
  border: 4px solid white;
}

.quots hr{
  margin-top: -30px;
  height: 30px;
  width: 100%;
  background: #EC2BEA;
position: absolute;

  z-index: 1;
 
}
@media (max-width: 600px) {
  .quots hr{
    display: none;
  }
}




/* ==============================Client ================================ */

.client-banner-side{
  background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1668488624/codler%20branding%20page/Asset_161_wauu04.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}
